import '@babel/polyfill';
jQuery(function ($) {
    console.log('gens.jsが読みこまれた');
    $('body').addClass('jquery-add-class');
});
const modal1 = document.querySelector('#movie01-modal');
const modal2 = document.querySelector('#movie02-modal');
const addYoutube01 = () => {
    const youtubeBox = document.querySelector('#youtube01');
    const addYoutubeIframe = `<iframe id="addedIframe" src="https://www.youtube.com/embed/iP-8tsiQJOY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    youtubeBox.insertAdjacentHTML('afterbegin', addYoutubeIframe);
};
const addYoutube02 = () => {
    const youtubeBox = document.querySelector('#youtube02');
    const addYoutubeIframe = `<iframe id="addedIframe" src="https://www.youtube.com/embed/ikTuMhLeAHY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    youtubeBox.insertAdjacentHTML('afterbegin', addYoutubeIframe);
};
const deleteYoutube = () => {
    const element = document.querySelector('#addedIframe');
    if (element) {
        element.remove();
    }
};
MicroModal.init({
    onShow: (modal) => {
        if (modal === modal1) {
            addYoutube01(modal);
        }
        else if (modal === modal2) {
            addYoutube02(modal);
        }
    },
    onClose: deleteYoutube
});
const swiperSlides = document.getElementsByClassName("swiper-slide");
const breakPoint = 768;
let swiper;
let swiperBool;
window.addEventListener("load", () => {
    if (breakPoint < window.innerWidth) {
        swiperBool = false;
    }
    else {
        createSwiper();
        swiperBool = true;
    }
}, false);
window.addEventListener("resize", () => {
    if (breakPoint < window.innerWidth && swiperBool) {
        swiper.destroy(false, true);
        swiperBool = false;
    }
    else if (breakPoint >= window.innerWidth && !swiperBool) {
        createSwiper();
        swiperBool = true;
    }
}, false);
const createSwiper = () => {
    swiper = new Swiper(".swiper", {
        loop: true,
        speed: 1500,
        centeredSlides: true,
        slidesPerView: 2.5,
        spaceBetween: 10,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
};
